<template>
  <div>
    <el-form ref="form"
             :rules="rules"
             label-position="top"
             :model="form">
      <el-form-item label="题目"
                    prop="topic_name">
        <el-input v-model="form.topic_name"
                  class="width300"
                  placeholder="请输入题目内容"></el-input>
      </el-form-item>
      <el-form-item label="答案列表"
                    required>
        <el-radio-group v-model="trueIndex">
          <el-row v-for="(item,index) in form.answer_cofig"
                  class="mt-15"
                  :key="index">
            <div class="mb-15">
              <el-radio :label="index">答案{{index+1}}</el-radio>
            </div>
            <div class="aic">
              <el-input type="textarea"
                        class="mr-15 width300"
                        v-model="item.answer"></el-input>
              <el-button type="danger"
                         size="small"
                         icon="el-icon-delete"
                         v-if="form.answer_cofig.length>2"
                         @click="delQuestion(index)"
                         circle></el-button>
            </div>
          </el-row>
        </el-radio-group>

      </el-form-item>
      <el-button size="small"
                 type="primary"
                 @click="addQuestion">新增一个</el-button>
      <el-form-item label="排序值"
                    prop="sort">
        <el-input class="width300"
                  v-model.number="form.sort"></el-input>
      </el-form-item>
      <el-button type="primary"
                 @click="submitForm('form')">{{form.id?'修改':'立即创建'}}</el-button>
    </el-form>

  </div>
</template>

<script>
import { setAppointTopic, getAppointTopic } from '@/api/market'
export default {
  name: 'ApponitmentSetTopic',
  data () {
    return {
      activity_id: '',
      trueIndex: '',
      form: {
        topic_name: '', // 题目
        answer_cofig: [{
          answer: '', // 问题内容
          is_correct: false // 是否为正确答案
        }, {
          answer: '', // 问题内容
          is_correct: false // 是否为正确答案
        }],
        sort: 0 // 排序值
      },
      rules: {
        topic_name: [
          { required: true, message: '请输入题目内容', trigger: 'blur' }
        ],
        sort: [
          { required: true, type: 'number', message: '请输入排序值', trigger: 'blur' }
        ]
      },
      dialogVisible: false,
      options: [],
      selectLoading: false
    }
  },
  created () {

  },
  mounted () {
    if (this.$route.query.id) {
      this.getAppointTopic()
    }
  },
  methods: {
    delQuestion (index) {
      this.form.answer_cofig.splice(index, 1)
    },
    addQuestion () {
      this.form.answer_cofig.push({
        answer: '', // 问题内容
        is_correct: false // 是否为正确答案
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.form.answer_cofig.map(item => {
            item.is_correct = false
          })
          this.form.answer_cofig[this.trueIndex].is_correct = true
          const res = await setAppointTopic({
            activity_id: this.$route.query.activity_id,
            ...this.form
          })
          if (res.meta.code === 0) {
            this.$notify({
              title: '成功',
              message: `${this.form.id ? '修改' : '创建'}成功`,
              type: 'success'
            })
            this.dialogVisible = false
            this.$router.push({ name: 'ApponitmentTopics', query: { id: this.$route.query.activity_id } })
          } else {
            this.$alert(res.meta.msg, {
              type: 'error',
              content: res.meta.msg
            })
          }
        }
      })
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    async getAppointTopic () {
      const res = await getAppointTopic({
        activity_id: this.$route.query.activity_id,
        id: this.$route.query.id
      })
      if (res.meta.code === 0) {
        this.form = res.data.topics
        this.trueIndex = this.form.answer_cofig.findIndex((item) => {
          return item.is_correct === true
        })
      } else {
        this.$message.error(res.meta.msg)
      }
    }
  }
}
</script>

<style scoped lang="scss">
</style>
